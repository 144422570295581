'use client';
import { Icon } from '@components';
import { Button } from 'antd';

export default function ButtonComponent({ type = 'primary', onClick = () => { }, htmlType, textButton = 'Enviar', disabled = false, align = 'right', icon = null, className = '' }) {
  const commonProps = {
    type,
    onClick,
    htmlType,
    disabled,
    align,
    className
  }
  if (icon) {
    commonProps.icon = <Icon icon={icon} />
    commonProps.iconPosition = 'start'
  }
  return <div style={{ textAlign: align }}>
    <Button style={{ cursor: "pointer" }} {...commonProps}>{textButton}</Button>
  </div>;
}
