import { Modal } from 'antd';

export default function ModalComponent({ children, stateModal: { isModalOpen, setIsModalOpen }, onOk, onCancel, hideFooter = undefined }) {
  const handleStateModal = () => {
    onOk()
    setIsModalOpen(!isModalOpen)
  }

  return <Modal
    width="100%"
    open={isModalOpen}
    onOk={handleStateModal}
    onCancel={onCancel ? onCancel : () => setIsModalOpen(false)}
    cancelText='Cancelar'
    okText='Aceptar'
    footer={hideFooter}
  >
    {children}
  </Modal>
}