'use client';
import { Icon } from '@components';
import { Button } from 'antd';
import Image from 'next/image';

export default function CustomButtonComponent({
  onClick = () => { },
  textButton = 'Enviar',
  disabled = false,
  icon = null,
  iconPosition = 'start',
  backgroundColor = null,
  textColor = null
}) {
  return <button
    className='cont_custom_button'
    style={{
      flexDirection: iconPosition === 'start' ? "row" : "row-reverse",
      backgroundColor: backgroundColor ?? backgroundColor,
      color: textColor ?? textColor
    }}
    disabled={disabled}
    onClick={onClick}
  >
    {icon && <img src={icon.src} alt='logo-mercadolibre' className='icon' />}
    <p
      className='text'
      style={{
        color: textColor ?? textColor
      }}
    >
      {textButton}
    </p>
  </button>;
}
