'use client';

import { useMemo, useState, useEffect } from "react";
import { Select } from "@components";
import { useHttp } from '@hooks/useHttp';
import { CFDI_USE_BY_FISCAL_REGIME } from "@constants/variables/cfdiUse";
import { API_ENDPOINT } from "@constants/variables/apiEndpoint";

export default function UsoCFDISelect({ fiscalRegime, value, onChange }) {
  const { GET } = useHttp()
  const [optionsCfdiUse, setOptionsCfdiUse] = useState([])

  useEffect(() => {
    getCfdiUse()
  }, [])

  const getCfdiUse = async () => {
    await GET({
      url: API_ENDPOINT.catalogs.facturama.cfdiUses, options: {
        callback: (response) => {
          if (response.length > 0) {
            setOptionsCfdiUse(response.map(({ Value, Name }) => ({ value: Value, label: `${Value} - ${Name}` })))
          }
        }
      }
    })
  }

  const options = useMemo(() => {
    let possibleOptions = []
    if (fiscalRegime) {
      Object.entries(CFDI_USE_BY_FISCAL_REGIME).forEach(([cfdiUse, fiscalRegimes]) => {
        if (fiscalRegimes.includes(Number(fiscalRegime))) {
          possibleOptions.push(cfdiUse)
        }
      })
    }
    const finalOptions = optionsCfdiUse.filter(option => possibleOptions.includes(option.value))
    return finalOptions
  }, [fiscalRegime])
  return (
    <div className="cont_select_cfdi_use">
      {fiscalRegime ? <Select options={options} value={value} onChange={onChange} /> :
        <p>Primero elija un régimen fiscal</p>
      }
    </div>
  )
}