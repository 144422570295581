'use client'
import { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
const { Item } = Form;
import { Button, Checkbox, Dragger, Inputs } from '@components'


export default function FormComponent({ arrayOfElements = [], onSubmit, onSubmitFailed, children, submitText, initialValues = {}, onChangeValues = () => { } }) {
  const [submittable, setSubmittable] = useState(false)
  const [form] = Form.useForm()
  const values = Form.useWatch([], form)

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => {
        setSubmittable(false);
      })
      .catch(() => {
        setSubmittable(true);
      });
    onChangeValues(values)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  // useEffect(() => {
  //   form.resetFields()
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [form])


  return <Form
    form={form}
    onFinish={onSubmit}
    onFinishFailed={onSubmitFailed}
    layout='vertical'
    validateMessages={validateMessages}
    className='cont_form_component'
    initialValues={initialValues}
  >
    {arrayOfElements.map(({ label, name, rules, propsInput, dependencies, typeItem = 'input' }, idx) => (
      <Item
        key={idx}
        label={typeItem !== 'checkbox' && label}
        name={name}
        dependencies={dependencies}
        rules={rules?.map(rule => rule)}
        valuePropName={typeItem === 'checkbox' ? 'checked' : typeItem === 'upload' ? 'files' : 'value'}
        hasFeedback
      >
        {typeItem === 'input' ? <Inputs.Input {...propsInput} /> :
          typeItem === 'number' ? <Inputs.InputNumber {...propsInput} /> :
            typeItem === 'autocomplete' ? <Inputs.Autocomplete {...propsInput} /> :
              typeItem === 'checkbox' ? <Checkbox {...propsInput} /> :
                typeItem === 'upload' ? <Item><Dragger {...propsInput} /></Item> :
                  typeItem === 'select' ? <Select {...propsInput} /> :
                    null
        }
      </Item>
    ))}
    <div className="cont_footer">
      <Item>
        <Button htmlType="submit" type='primary' textButton={submitText} disabled={submittable} align='left' />
      </Item>
      {children}
    </div>
  </Form>
}

const validateMessages = {
  required: '${label} es requerido!',
  types: {
    email: '${label} no es un correo válido!',
    password: '${label} no es una contraseña válida!',
    select: '${label} es requerido!'
  },
  string: {
    min: '${label} debe tener al menos ${min} caracteres'
  }
}
