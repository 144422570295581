import { Select } from 'antd';

// /**
//  * @param {Array<{label: string, value: string}>} options
//  * @param {string} value
//  * @param {boolean} disabled
//  * @param {function} onChange
//  * @returns {JSX.Element}
//  */

export default function SelectComponent({ options, value, disabled, onChange }) {
  return <Select
    value={value}
    disabled={disabled}
    options={options}
    onChange={onChange}
  />;
}
