'use client';

import Icon from '@ant-design/icons';
import useIcons from '@hooks/useIcons';

export default function IconComponent({ icon, spin = false }) {
  const searchedIcon = useIcons(icon)
  return <Icon
    className='icon_component'
    component={searchedIcon}
    spin={spin}
  />
}
