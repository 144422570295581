'use client'
import { InboxOutlined } from "@ant-design/icons"
import { Upload } from "antd"
import { useState } from "react"
const { Dragger } = Upload

export default function DraggerComponent({ name, onChange, customRequest, formatAccept = "*", single = false, value = [], onRemove }) {
  return <Dragger
    accept={formatAccept}
    multiple={single}
    name={name}
    maxCount={single ? 1 : undefined}
    onChange={onChange}
    customRequest={customRequest}
    fileList={value}
    onRemove={onRemove}
  >
    <p className="ant-upload-drag-icon">
      <InboxOutlined />
    </p>
    <p className="ant-upload-text">Haga clic o arrastre el archivo a esta área para cargarlo.</p>
    {/* <p className="ant-upload-hint">456 */}
  </Dragger>
}
