import { InputNumber } from 'antd';

export default function InputNumberComponent({ placeholder, onChange, value, min, max, formatter, parser, className, defaultValue = 1, prefix = "" }) {
  const commonProps = {
    value,
    min,
    max,
    placeholder,
    formatter,
    parser,
    onChange,
    defaultValue,
    prefix,
    className: `cont_input_number ${className}`
  }
  return <InputNumber {...commonProps} />;
}
