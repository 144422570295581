import { Input } from 'antd';
const { Password } = Input;

export default function InputComponent({ type = 'text', size = 'middle', onChange, placeholder, value, prefix, suffix, status, className = '', disabled = false }) {
  const commonProps = {
    onChange,
    value,
    placeholder,
    prefix,
    suffix,
    size,
    status,
    className: `cont_input ${className}`,
    disabled
  }

  if (type === 'password') {
    return <Password
      {...commonProps}
    />
  }
  return <Input
    type={type}
    {...commonProps}
  />;
}
