export const CFDI_USE_BY_FISCAL_REGIME = {
  G01: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  G02: [601, 603, 606, 612, 616, 620, 621, 622, 623, 624, 625, 626],
  G03: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I01: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I02: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I03: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I04: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I05: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I06: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I07: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  I08: [601, 603, 606, 612, 620, 621, 622, 623, 624, 625, 626],
  D01: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D02: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D03: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D04: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D05: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D06: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D07: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D08: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D09: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  D10: [605, 606, 608, 611, 612, 614, 607, 615, 625],
  S01: [601, 603, 605, 606, 608, 610, 611, 612, 614, 616, 620, 621, 622, 623, 624, 607, 615, 625, 626],
  CP01: [601, 603, 605, 606, 608, 610, 611, 612, 614, 616, 620, 621, 622, 623, 624, 607, 615, 625, 626],
  CN01: [605],
}

