import { DatePicker } from "antd";
import es_ES from "antd/locale/es_ES";

function RangeDatePicker({ onChange, value, minDate, maxDate }) {
  return (
    <DatePicker.RangePicker
      allowClear
      value={value}
      onChange={onChange}
      format="DD/MM/YYYY"
      style={{ width: "100%" }}
      minDate={minDate}
      maxDate={maxDate}
      locale={es_ES.DatePicker}
    />
  )
}

export {
  RangeDatePicker
}